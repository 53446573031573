<template>
	<div class="page-container">
		<nav-bar title="合格证查询" id="navbar"></nav-bar>
		<van-form @submit="onSearch" ref="formData" action="" v-if="!showResult">
			<van-field
				v-model="certNum"
				name="certNum"
				label="身份证号"
				placeholder="身份证号码"
				maxlength="18"
			/>
			<van-field
				v-model="studentName"
				name="studentName"
				label="真实姓名"
				placeholder="真实姓名"
			/>
			<div style="margin: 16px">
				<van-button round block type="info" native-type="submit">查询</van-button>
			</div>
		</van-form>
		<div v-if="showResult">
			<van-cell-group title="基本信息" inset>
				<van-cell title="姓名" :value="studentName" />
				<van-cell title="身份证号" :value="certNum" />
				<van-cell title="合格证数" :value="totalCount" />
			</van-cell-group>

			<van-cell-group :title="item.planName" inset v-for="(item, index) of studyList" :key="index">
				<van-cell title="年度" :value="item.year" />
				<van-cell title="成绩" :value="item.score" v-if="cityCode!=='152900'" />
				<van-cell title="开始时间" :value="item.beginDate" />
				<van-cell title="结束时间" :value="item.endDate" />
				<van-cell title="下载合格证" is-link value="点击下载" @click="getCertDownloadUrl(item)" style="color: #0064C8;" />
			</van-cell-group>

			<div style="margin: 16px">
				<van-button round block type="info" @click="goBack">返回</van-button>
			</div>
		</div>
		<!-- loading 遮罩层 -->
		<van-overlay :show="showLoading" color="#1989fa">
			<div class="wrapper">
				<div class="block">
					<van-loading size="24px" vertical>
						<span style="color:#4994df">{{ title || '加载中...' }}</span>
					</van-loading>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import {NavBar, Toast} from "vant";
	import {findStudyCertByCertNumAndName, getStudyCertList, getCertDownloadUrl, reloadCertDownloadUrl} from "../api/user";
	export default {
		name: "study_certificate-query",
		components: { NavBar },
		data() {
			return {
				studentName: '',
				certNum: '',
				studentId: 0,
				totalCount: 0,
				showResult: false,
				showLoading: false,
				title: null
			}
		},
		methods: {
			findStudyCertByCertNumAndName() {
				this.showLoading = true;
				findStudyCertByCertNumAndName({"staffName": this.studentName, "idCardNo": this.certNum})
					.then((res) => {
						if(res) {
							this.studentId = res.basePkId;
							this.getStudyCertList();
						} else {
							this.showLoading = false;
							Toast.fail("抱歉，未查询到学员信息");
						}
					})
					.catch(() => {
						this.showLoading = false;
					});
			},
			getStudyCertList() {
				getStudyCertList(this.studentId)
					.then((res) => {
						if(res) {
							this.studentName = res.studentName;
							this.certNum = res.certNum;
							this.cityCode = res.cityCode;
							this.totalCount = res.signUpRecord.length;
							this.studyList = res.signUpRecord;
							this.showResult = true;
						}
						this.showLoading = false;
					})
					.catch(() => {
						this.showLoading = false;
					});
			},
			getCertDownloadUrl(obj) {
				this.showLoading = true;
				getCertDownloadUrl(obj.planId, obj.certificateId).then((res) => {
					this.showLoading = false;
					if(res) {
						window.location.href = res.url;
					}
				}).catch(() => {
					this.showLoading = false;
				});
			},
			reloadCertDownloadUrl() {
				this.showLoading = true;
				reloadCertDownloadUrl(obj.planId, obj.certificateId).then((res) => {
					this.showLoading = false;
					if(res) {
						window.location.href = res.url;
					}
				}).catch(() => {
					this.showLoading = false;
				});
			},
			onSearch() {
				if(this.certNum === '') {
					Toast.fail("请您输入身份证号码");
					return;
				}
				if(this.studentName === '') {
					Toast.fail("请您输入真实姓名");
					return;
				}
				this.findStudyCertByCertNumAndName();
			},
			download(obj) {
				window.location.href = "/renren-fast/app/exp/download/" + obj.planId + "/" + obj.certificateId;
			},
			goBack() {
				this.showResult = false;
			}
		}
	}
</script>

<style scoped>
	#navbar {
		background-color: #409eff;
	}
	#navbar /deep/ .van-ellipsis {
		color: #fff !important;
	}
	#navbar /deep/ .van-nav-bar__text {
		color: #fff !important;
	}
	.van-cell-group__title {
		background-color: #f5f5f5;
	}
</style>
